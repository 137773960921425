// import queryString from 'query-string';
import $ from 'jquery';
import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet';
import service from '../http.commons';

const LoginView = () => {
    const [systemError, setSystemError] = useState(false);
    
    const recaptchaKey:string = process.env.REACT_APP_RECAPTCHA_KEY as string;
    const [recaptchaVerified, setRecaptchaVerfified] = useState(false);
    const [tryChallengeRecaptcha, setTryChallengeRecaptcha] = useState(false);

    const token = sessionStorage.getItem('token');

    if(token !== undefined && token !== null) {
        service.post('/', null, {headers:{'authorization': 'bearer ' + token}})
            .then(response => {
                $('.preloader').fadeIn(500);
                setAuthenticated(true);
                sessionStorage.setItem('token', response.data.access_token);
                window.location.href=response.data.redirect;
            });
    }

    const [username, setUsername] = useState("");    
    const [password, setPassword] = useState("");    
    const [tryAccess, setTryAccess] = useState(false);    
    const [credentialsValid, setCredentialsValid] = useState(false);    
    const [authenticated, setAuthenticated] = useState(false);    

    const validatePassword = (password: string) => {
        const re = new RegExp('^[\\s\\S]{8,16}$');
        const valid = re.test(password);
        setTryAccess(false);
        setCredentialsValid(valid);
        setPassword(password);
        return valid;
    };

    const validateUsername = (username:string) => {
        let pattern = '^[a-z0-9]{6,16}$';
        if(username.indexOf('@') > -1) {
            pattern = '^(([^<>()[]\\.,;:\\s@"]+(\\.[^<>()[]\\.,;:\\s@"]+)*)|(".+"))@(([[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
        }
        const re = new RegExp(pattern);
        const valid = re.test(username);
        setTryAccess(false);
        setCredentialsValid(valid);
        setUsername(username);
        return valid;
    };
    
    const handleVerify = (token:any) => {
        if (tryChallengeRecaptcha === true) {
            return;
        }
        service.post('/bot-challenge', {"token":token}).then(response => {
            setRecaptchaVerfified(true);
            setTryChallengeRecaptcha(true);
        }).catch(reason => setTryChallengeRecaptcha(true));
    };

    const authentication = () => {
        setSystemError(false);
        
        if(recaptchaVerified === false && tryChallengeRecaptcha === true) {
            setCredentialsValid(false);
        }

        setTryAccess(true);
        $('.preloader').fadeIn(500);
        if(credentialsValid === true) {
            
            const data = {"username": username, "password": password};

            service.post('/', data)
            .then(response => {

                ReactGA.event({
                    category: 'User (' + username + ')',
                    action: 'Login'
                  });

                setAuthenticated(true);
                sessionStorage.setItem('token', response.data.access_token);
                window.location.href=response.data.redirect;
            })
            .catch(e => {
                $('.preloader').fadeOut(500);
                setCredentialsValid(false);
                if(e.response) {
                    ReactGA.event({
                        category: 'User',
                        action: 'Login Error ('+e.response.status+')'
                    });
                }
                if (e.request && e.response.status !== 401) {
                    console.log('error request',e.request)
                    ReactGA.event({
                        category: 'User',
                        action: 'Login System Error'
                    });
                    setSystemError(true);
                }
              });
        } else {
            $('.preloader').fadeOut(500);
            ReactGA.event({
                category: 'User',
                action: 'Login Invalid credentials'
            });
        }
    };

    return (
        <>
            <Helmet>
                <title>{(process.env.REACT_APP_TITLE as string) + ' - Login'}</title>
                <link rel="canonical" href={(process.env.REACT_APP_BASE_DOMAIN as string) + window.location.pathname + window.location.search} />
            </Helmet>
            <div id="home" className="text-center header-hero bg_cover d-lg-flex align-items-center" style={{ backgroundImage: "url(assets/images/header-hero.jpg)" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <img src="assets/images/logo4.png" style={{width: "250px"}} alt="logo"/>
                            <br />
                            <br />
                            <div className="header-hero-content header-hero-content-mobile">
                                {systemError === true ?                                
                                    <h5 className="alert alert-warning">Ocorreu um comportamento inesperado, por favor, aguarde alguns minutos e tente novamente. Caso a mensagem continue a ser apresentada, fa&ccedil;a contato atrav&eacute;s do e-mail <a href="mailto:suporte@4fuse.com.br?subject=Ocorreu um problema para recuperar a minha senha">suporte@4fuse.com.br</a> </h5>
                                    : ''
                                }
                                {credentialsValid === false && tryAccess === true && username !== "" && password !== "" ?                                
                                    <h5 className="alert alert-danger">N&#227;o foi poss&#237;vel realizar o seu acesso, verifique o seu usu&#225;rio e senha.</h5>
                                    : ''
                                }
                                {credentialsValid === true && tryAccess === true  && authenticated === true ?                                
                                    <h5 className="alert alert-success">Acesso realizado com sucesso, aguarde o redirecionamento para o painel.</h5>
                                    : ''
                                }
                                <h4 className="text wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s" style={{paddingLeft: "20px", maxWidth: "100%"}}>Informe nos campos abaixo o seu e-mail e senha de acesso para entrar no painel de controle ou executar a a&#231;&#227;o solicitada.</h4>
                                <p>caso deseje retornar para o site principal <a href="/">clique aqui</a></p>
                                <div className="header-singup wow fadeInUp" style={{marginTop:"10px"}} data-wow-duration="1s" data-wow-delay="0.8s">
                                    <input type="text" placeholder="email@seudominio.br" value={username} onChange={(e)=> validateUsername(e.target.value)}/>
                                </div>
                                <div className="header-singup wow fadeInUp" style={{marginTop:"10px"}} data-wow-duration="1s" data-wow-delay="0.8s">
                                    <input type="password" placeholder="sua senha" value={password} onChange={(e) => validatePassword(e.target.value)}/>
                                    <button className="main-btn" onClick={() => authentication()}>Acessar</button>
                                    <small>Deve conter no <strong>m&iacute;nimo 8 caracteres</strong> entre letras e n&uacute;meros.</small>
                                </div>
                                <div>
                                    <a href="/forgot-password">Esqueci a minha senha</a> <br />
                                </div>
                                <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
                                    <GoogleReCaptcha onVerify={handleVerify} />
                                </GoogleReCaptchaProvider>
                            </div> 
                        </div>
                    </div> 
                </div> 
                <div className="header-hero-image d-flex align-items-center wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="1.1s">
                    <div className="image mobile">
                        <img src="assets/images/header-software-app-8.png" style={{height: "400px", marginLeft: "100px"}} alt="Gerencie o seu negócio"/>
                    </div>
                </div> 
                <div className="mobile-space"></div>
            </div>
        </>
        );
};

export default LoginView;