const Navbar = () => {
    return <div className="navbar-area headroom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <nav className="navbar navbar-expand-lg">
                                <a className="navbar-brand" href="/#">
                                    <img src="assets/images/logo4.png" style={{width:"200px"}} alt="Logo"/>
                                </a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="toggler-icon"></span>
                                    <span className="toggler-icon"></span>
                                    <span className="toggler-icon"></span>
                                </button>

                                {/* <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                    <ul id="nav" className="navbar-nav m-auto">
                                        <li className="nav-item active">
                                            <a href="/#">Home</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/#about">Sobre n&oacute;s </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/#pricing">Servi&ccedil;os</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/#blog">Blog</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/#contact">Contato</a>
                                        </li>
                                    </ul>
                                </div>
                                
                                <div className="navbar-btn d-none d-sm-inline-block">
                                    <a className="main-btn" href="/login">Painel de controle</a>
                                </div> */}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
}

export default Navbar;