import axios from "axios";

const serviceEndpoint: string = process.env.REACT_APP_SERVICE_ENDPOINT as string;

const service = axios.create({
  baseURL: serviceEndpoint,
  headers: {
    "Content-type": "application/json"
  }
});
export default service;