import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
// import Pricing from "../components/Pricing";
// import Blog from "../components/Blog";

const Home = () => {
    return <div>

        <header>
            <Navbar></Navbar>

            <Hero></Hero>
        </header>

        {/* <Pricing></Pricing>

        <Blog></Blog> */}
        
    </div>;
}

export default Home;