const Footer = () => {
    return <footer id="footer" className="footer-area bg_cover" style={{backgroundImage: "url(assets/images/footer-bg.jpg)"}}>
                <div className="container">
                    <div className="footer-widget pt-30 pb-70">
                        <div className="row">
                            <div className="col-lg-3 col-sm-6 order-sm-1 order-lg-1">
                                <div className="footer-about text-center pt-40">
                                    <a href="/#">
                                        <img src="assets/images/logo4.png" style={{width: "150px"}} alt="Logo"/>
                                    </a>
                                    <p className="text text-left"><strong>Idealizar sonhos e construir realidades, nossa missão de trabalho.</strong></p>
                                    <p className="text text-left"><small>V&#225; firme na dire&#231;&#227;o da sua meta. Porque o pensamento cria, o desejo atrai e a f&#233; realiza.</small></p>
                                </div> 
                            </div>
                            <div className="col-lg-3 col-sm-6 order-sm-3 order-lg-2">
                                <div className="footer-link pt-40">
                                    <div className="footer-title">
                                        <h5 className="title">Servi&ccedil;os</h5>
                                    </div>
                                    <ul>
                                        <li><a href="/#">Consultoria de neg&oacute;cios</a></li>
                                        <li><a href="/#">Marketing Digital</a></li>
                                        <li><a href="/#">Transforma&ccedil;&atilde;o digital</a></li>
                                        <li><a href="/#">Desenvolvimento Web</a></li>
                                    </ul>
                                </div> 
                            </div>
                            <div className="col-lg-3 col-sm-6 order-sm-4 order-lg-3">
                                <div className="footer-link pt-40">
                                    <div className="footer-title">
                                        <h5 className="title">Sobre n&oacute;s</h5>
                                    </div>
                                    <ul>
                                        <li><a href="/#">Overview</a></li>
                                        <li><a href="/#">Porque n&oacute;s</a></li>
                                        <li><a href="/#">Awards & Reconhecimentos</a></li>
                                        <li><a href="/#">Team</a></li>
                                    </ul>
                                </div> 
                            </div>
                            <div className="col-lg-3 col-sm-6 order-sm-2 order-lg-4">
                                <div className="footer-contact pt-40">
                                    <div className="footer-title">
                                        <h5 className="title">Contact Info</h5>
                                    </div>
                                    <div className="contact pt-10">
                                        <p className="text">suporte@4fuse.com.br</p>

                                        <ul className="social mt-40">
                                            <li><a href="/#"><i className="lni-facebook"></i></a></li>
                                            <li><a href="/#"><i className="lni-twitter"></i></a></li>
                                            <li><a href="/#"><i className="lni-instagram"></i></a></li>
                                            <li><a href="/#"><i className="lni-linkedin"></i></a></li>
                                        </ul>
                                    </div> 
                                </div> 
                            </div>
                        </div> 
                    </div> 
                    <div className="footer-copyright text-center">
                        <p className="text">© 4FUSE SOLUTIONS 2016. All Rights Reserved.</p>
                    </div>
                </div> 
            </footer>;
};

export default Footer;