import { useEffect, useState } from "react";
import { Button, Form, Modal, Offcanvas } from "react-bootstrap";
import { useCookies } from "react-cookie";
import ReactGA from 'react-ga';

const AgreementCookies = () => {

  const [cookies, setCookie] = useCookies(['UserAgreement']);
  const [cookieAdvertisement, setCookieAdvertisement] = useCookies(['UserAgreementAdvertisement']);
  const [cookieAnalytics, setCookieAnalytics] = useCookies(['UserAgreementAnalytics']);
  const [cookiesFunctional, setCookieFunctional] = useCookies(['UserAgreementFunctional']);
  
  const showAgreement = cookies.UserAgreement === undefined ? true : false;
  
  const showAgreementGear = cookies.UserAgreement === "0" || cookies.UserAgreement === "1" ? true : false;

  const [agreementAll, setAgreementAll] = useState(cookies.UserAgreement === "1" || cookies.UserAgreement === undefined);
  const [agreementAdvertisement, setAgreementAdvertisement] = useState(cookieAdvertisement.UserAgreementAdvertisement === "1" || cookies.UserAgreement === undefined);
  const [agreementAnalytics, setAgreementAnalytics] = useState(cookieAnalytics.UserAgreementAnalytics === "1" || cookies.UserAgreement === undefined);
  const [agreementFunctional, setAgreementFunctional] = useState(cookiesFunctional.UserAgreementFunctional === "1" || cookies.UserAgreement === undefined);

  useEffect(() => {
    setAgreementAll(agreementAdvertisement === true && agreementAnalytics === true && agreementFunctional === true);
  }, [agreementAdvertisement, agreementAnalytics, agreementFunctional]);

  const [show, setShow] = useState(showAgreement);

  const handleClose = (accepted: boolean = false) => {
    
    ReactGA.event({
        category: 'User',
        action: 'Cookie use accepted'
    });

    setCookie('UserAgreement', agreementAll === true || accepted ? 1 : 0, { path: '/' });
    setCookieAdvertisement('UserAgreementAdvertisement', agreementAll === true || accepted || agreementAdvertisement === true ? 1 : 0, { path: '/' });
    setCookieAnalytics('UserAgreementAnalytics', agreementAll === true || accepted || agreementAnalytics === true ? 1 : 0, { path: '/' });
    setCookieFunctional('UserAgreementFunctional', agreementAll === true || accepted || agreementFunctional === true ? 1 : 0, { path: '/' });
    setShow(false);
  };

  const [showAgreementConfig, setShowAgreementConfig] = useState(false);

  const handleShowAgreementConfig = () => {
    
    ReactGA.event({
        category: 'User',
        action: 'Show agreement configure'
    });

    setShowAgreementConfig(true)
  };
  const handleCloseAgreementConfig = () => {
    handleClose();
    setShowAgreementConfig(false);
  };

  const handleAgreementAll = (accept: boolean) => {
    setAgreementAdvertisement(accept);
    setAgreementAnalytics(accept);
    setAgreementFunctional(accept);
    setAgreementAll(accept);
  };
  
  return (
    <>
      {showAgreementGear === true ? 
        <button className="cookie-gear" style={{border:"0"}} onClick={handleShowAgreementConfig}>
            <i className="lni-cog"></i>
        </button>
      : ''}
      
      <Offcanvas show={show} onHide={handleClose} placement="bottom" scroll={true} backdrop={false} style={{"height":"20vh"}}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Pol&iacute;tica de privacidade</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{"paddingTop":0, "paddingBottom":0}}>
          <div className="text-center"></div>
        Utilizamos cookies para garantir a melhor experi&ecirc;ncia em nosso site. Isto inclui cookies de sites de m&iacute;dias sociais de terceiros e cookies de publicidade que podem analisar o uso deste site. 
        <br />Para concordar, clique em <strong>&quot;Entendi&quot;</strong> ou ao fechar a esta mensagem seguir&aacute; concordando com a pol&iacute;tica de privacidade. Para desativar, clique em &quot;Configura&ccedil;&otilde;es de cookies&quot;
        <br />
        <br />
        <Button onClick={() => handleClose(true)}>Entendi</Button> <Button variant="link" onClick={handleShowAgreementConfig}>Configura&ccedil;&otilde;es de cookies</Button>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={showAgreementConfig} onHide={handleCloseAgreementConfig} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title> 
          <img src="assets/images/logo4.png" style={{width:"100px", float:"left"}} alt="Logo"/> <span style={{paddingTop:"40px", marginLeft:"20px"}}><strong> Como utilizamos Cookies</strong></span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
            <Form.Label htmlFor="all-agreement-opt" column="sm">
              <strong>Requeridos, permitir todos</strong> &nbsp;
            </Form.Label>
            <Form.Check 
              inline
              type="switch"
              id="all-agreement-opt"
              checked={agreementAll}
              onChange = {(e) => handleAgreementAll(e.target.checked)}
              />
              <p>Estes cookies e tecnologias de rastreamento s&atilde;o necess&aacute;rios para auxiliar no funcionamento correto dos nossos sites. Estes cookies permitem que voc&ecirc; navegue em nosso site e utilize recursos essenciais, como &aacute;rea seguras e carrinho de compras, por exemplo.</p>

          
            <Form.Label htmlFor="publicidade-opt" column="sm">
              <strong>Publicidade</strong> &nbsp;
            </Form.Label>
            <Form.Check 
              inline
              type="switch"
              id="publicidade-opt"
              checked={agreementAdvertisement}
              onChange = {(e) => setAgreementAdvertisement(e.target.checked)}
              />
            <p>Estas tecnologias de cookies e rastreamento nos permitem fornecer conte&uacute;dos relevantes para os seus interesses espec&iacute;ficos, incluindo an&uacute;ncios. Estes conte&uacute;dos podem ser entregues em nossos sites ou em sites ou servi&ccedil;os de terceiros. Eles nos permitem entender e melhorar a relev&acirc;ncia dos nossos an&uacute;ncios. Eles podem rastrear informa&ccedil;&otilde;es pessoais, incluindo seu endere&ccedil;o de IP.</p>
            
            <Form.Label htmlFor="analytics-opt" column="sm">
              <strong>Analytics</strong> &nbsp;
            </Form.Label>
            <Form.Check 
              inline
              type="switch"
              id="analytics-opt"
              checked={agreementAnalytics}
              onChange = {(e) => setAgreementAnalytics(e.target.checked)}
              />
            <p>Estas tecnologias de cookies e rastreamentos nos ajudam a entender como os clientes e visitantes interagem com nossos sites. Eles nos fornecem informa&ccedil;&otilde;es sobre &aacute;reas visitadas, tempo gasto nos sites, transa&ccedil;&otilde;es realizadas e tamb&eacute;m mensagens de erro que voc&ecirc; recebe. Estes cookies nos permitem melhorar o desempenho de nossos sites. Eles podem coletar seu endere&ccedil;o de IP, mas apenas com o objetivo de identificar a localiza&ccedil;&atilde;o geral dos visitantes e identificar tr&aacute;fego fraudulento ou spam.</p>
            
            <Form.Label htmlFor="funcional-opt" column="sm">
              <strong>Funcional</strong> &nbsp;
            </Form.Label>
            <Form.Check 
              inline
              type="switch"
              id="funcional-opt"
              checked={agreementFunctional}
              onChange = {(e) => setAgreementFunctional(e.target.checked)}
              />
            <p>Estes cookies e tecnologias de rastreamento permitem que nossos sites lembrem-se das escolha que voc&ecirc; fez na sua &uacute;ltima visita para oferecer mais funcionalidade e uma experi&ecirc;ncia personalizada. Por exemplo, quando voc&ecirc; seleciona uma moeda espec&iacute;fica em um de nossos sites, lembraremos da sua sele&ccedil;&atilde;o de moeda quando voc&ecirc; retornar.&nbsp;</p>
          </Form>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="primary" onClick={handleCloseAgreementConfig}>
            SALVAR CONFIGURA&Ccedil;&Atilde;O DE COOKIES
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
};

export default AgreementCookies;