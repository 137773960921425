const Hero = () => {
    return <div id="home" className="header-hero bg_cover d-lg-flex align-items-center" style={{ backgroundImage: "url(assets/images/header-hero.jpg)" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="header-hero-content">
                                <h1 className="hero-title wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">Seu principal <b><span>Parceiro</span></b> para o <b>Crescimento do Seu Neg&oacute;cio.</b></h1>
                                <p className="text wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">O ambiente &#225;gil e necess&#225;rio, com solu&ccedil;&otilde;es de ponta para sustentar e apoiar no r&#225;pido crescimento do seu neg&#243;cio.</p>
                                {/* <div className="header-singup wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.8s">
                                    <input type="text" placeholder="meudominio.br"/>
                                    <a className="main-btn" data-scroll-nav="0" href="#pricing">Come&ccedil;ar</a>
                                </div> */}
                                <br />
                                <h3>Aguarde Novidades! Estamos melhorando nossas solu&ccedil;&otilde;es para melhor atend&ecirc;-lo.</h3>
                                <br />
                                <a className="main-btn" href="/login">Painel de controle</a>
                                <br />
                                <br />
                            </div> 
                        </div>
                    </div> 
                </div> 
                <div className="header-hero-image d-flex align-items-center wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="1.1s">
                    <div className="image">
                        <img src="assets/images/header-software-app-3.png" style={{maxHeight: "500px", marginLeft: "100px"}} alt="Parceria e sustentação de negócios"/>
                    </div>
                </div> 
            </div> 
};

export default Hero;