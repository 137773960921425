import queryString from 'query-string';
import { useState } from "react";
import ReactGA from 'react-ga';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet';
import service from "../http.commons";

const SuspendPassword = () => {
    const [systemError, setSystemError] = useState(false);

    const recaptchaKey:string = process.env.REACT_APP_RECAPTCHA_KEY as string;
    const [recaptchaVerified, setRecaptchaVerfified] = useState(false);
    const [tryChallengeRecaptcha, setTryChallengeRecaptcha] = useState(false);

    const [validCode, setValidCode] = useState(true);
    
    const [suspendConfirmation, setSuspendConfirmation] = useState(false);
    const [emailConfirmation, setEmailConfirmation] = useState("");
    const [redirect, setRedirect] = useState("");

    const q = queryString.parse(window.location.search)
    
    service.post(`/validate-link?code=${q.code}`)
        .then(response => {
            setValidCode(true);
            })
            .catch(e => {
                if(emailConfirmation !== '') {
                    return
                }

                setValidCode(false);
                setTimeout(() => {
                    window.location.href = '/forgot-password';
                }, 5000);
            });

    const handleVerify = (token:any) => {
        if (tryChallengeRecaptcha === true) {
            return;
        }
        service.post('/bot-challenge', {"token":token}).then(response => {
            setRecaptchaVerfified(true);
            setTryChallengeRecaptcha(true);
        }).catch(reason => setTryChallengeRecaptcha(true));
    };

    const suspendPassword = () => {
        setSystemError(false);

        if(recaptchaVerified === false && tryChallengeRecaptcha === true) {
            return;
        }

        $('.preloader').fadeIn(500);
        if(validCode === false){
            return
        }

        service.post(`/suspend-password?code=${q.code}`)
        .then(response => {
            $('.preloader').fadeOut(50);
            
            ReactGA.event({
                category: 'User',
                action: 'Suspend Password'
              });

            if(response.data.status === 401) {
                setTimeout(() => {
                    window.location.href = response.data.redirect;
                }, 2000);
            }
            if(response.data.status === 200) {
                setSuspendConfirmation(true);
                setEmailConfirmation(response.data.obliterated_email)
                setRedirect(response.data.redirect);
                setTimeout(() => {
                    window.location.href = response.data.redirect;
                }, 2000);
            }
            
          })
          .catch(e => {
            $('.preloader').fadeOut(500);
            if(e.response) {
                ReactGA.event({
                    category: 'User',
                    action: 'Suspend Password Error ('+e.response.status+')'
                  });
            }
            if (e.request) {
                ReactGA.event({
                    category: 'User',
                    action: 'Suspend Password System Error'
                  });
                setSystemError(true);
            }
          });
    };
    
    return (
        <>
            <Helmet>
                <title>{(process.env.REACT_APP_TITLE as string) + ' - Suspensão de acesso'}</title>
                <link rel="canonical" href={(process.env.REACT_APP_BASE_DOMAIN as string) + window.location.pathname + window.location.search} />
            </Helmet>

            <div id="home" className="text-center header-hero bg_cover d-lg-flex align-items-center" style={{ backgroundImage: "url(assets/images/header-hero.jpg)" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <img src="assets/images/logo4.png" style={{width: "250px"}} alt="logo"/>
                            <div className="header-hero-content">
                                {systemError === true ?                                
                                    <h5 className="alert alert-warning">Ocorreu um comportamento inesperado, por favor, aguarde alguns minutos e tente novamente. Caso a mensagem continue a ser apresentada, fa&ccedil;a contato atrav&eacute;s do e-mail <a href="mailto:suporte@4fuse.com.br?subject=Ocorreu um problema para recuperar a minha senha">suporte@4fuse.com.br</a> </h5>
                                    : ''
                                }
                                {recaptchaVerified === false && tryChallengeRecaptcha === true ?                                
                                    <h5 className="alert alert-danger">Identificamos um poss&iacute;vel acesso de rob&ocirc;, por favor, atualize a p&aacute;gina e tente novamente. Caso a mensagem continue a ser apresentada, fa&ccedil;a contato atrav&eacute;s do e-mail <a href="mailto:suporte@4fuse.com.br?subject=Estou sendo identificado como bot">suporte@4fuse.com.br</a> </h5>
                                    : ''
                                }
                                {validCode === false && emailConfirmation === '' ?                                
                                    <h5 className="alert alert-danger">Este link n&atilde;o &eacute; mais v&aacute;lido, voc&ecirc; ser&aacute; redirecionado para p&aacute;gina de recupera&ccedil;&atilde;o de senha.</h5>
                                    : ''
                                }
                                {suspendConfirmation !== false ?                                
                                    <h5 className="alert alert-success">
                                        <strong>A sua senha foi modificada e foi enviado um e-mail de confirmação para <span>{emailConfirmation}</span>.</strong> <br />
                                        <small>Em instantes voc&ecirc; ser&aacute; redirecionado para a p&aacute;gina de recupera&ccedil;&atilde;o de acesso ou <a href={redirect}>clique aqui</a>.</small>
                                    </h5>
                                    : ''
                                }
                                {validCode === true && emailConfirmation === ''?
                                    <span>
                                        <h4 className="text wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s" style={{paddingLeft: "20px", maxWidth: "100%"}}>
                                            Para confirmar a suspens&atilde;o de senha, precione o bot&atilde;o abaixo. <br />
                                            Ap&oacute;s confirmar a suspens&atilde;o voc&ecirc; ser&aacute; redirecionado para a tela de recupera&ccedil;&atilde;o de senha
                                        </h4>
                                        
                                        <div className="wow fadeInUp" style={{marginTop:"10px"}} data-wow-duration="1s" data-wow-delay="0.8s">
                                            <button className="main-btn" onClick={() => suspendPassword()}>Confirmar suspens&atilde;o senha</button>
                                        </div>
                                    </span>
                                    : ''
                                }
                                <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
                                    <GoogleReCaptcha onVerify={handleVerify} />
                                </GoogleReCaptchaProvider>
                            </div> 
                        </div>
                    </div> 
                </div> 
                <div className="header-hero-image d-flex align-items-center wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="1.1s">
                    <div className="image">
                        <img src="assets/images/header-software-app-10.jpg" style={{height: "400px", marginLeft: "100px"}} alt="Gerencie o seu negócio"/>
                    </div>
                </div> 
            </div>
        </>
        );
};

export default SuspendPassword;