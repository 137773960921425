import { useState } from "react";
import ReactGA from 'react-ga';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet";
import service from "../http.commons";

const ForgotPassword = () => {
    const [systemError, setSystemError] = useState(false);

    const recaptchaKey:string = process.env.REACT_APP_RECAPTCHA_KEY as string;
    const [recaptchaVerified, setRecaptchaVerfified] = useState(false);
    const [tryChallengeRecaptcha, setTryChallengeRecaptcha] = useState(false);

    const [email, setEmail] = useState("");
    const [emailConfirmation, setEmailConfirmation] = useState("");

    const handleVerify = (token:any) => {
        if (tryChallengeRecaptcha === true) {
            return;
        }
        
        service.post('/bot-challenge', {"token":token}).then(response => {
            setRecaptchaVerfified(true);
            setTryChallengeRecaptcha(true);
        }).catch(reason => setTryChallengeRecaptcha(true));
    };

    const recoverPassword = () => {
        setSystemError(false);

        if(recaptchaVerified === false && tryChallengeRecaptcha === true) {
            return;
        }

        $('.preloader').fadeIn(500);
        service.post('/recovery-password',{"email": email})
        .then(response => {
            $('.preloader').fadeOut(50);
            
            ReactGA.event({
                category: 'User (' + email + ')',
                action: 'Recover Password'
              });

            setEmailConfirmation(response.data.obliterated_email)
            
            setTimeout(() => {
                window.location.href=response.data.redirect;
            }, 5000);
          })
          .catch(e => {
            $('.preloader').fadeOut(500);
            if(e.response) {
                ReactGA.event({
                    category: 'User',
                    action: 'Recover Password Error ('+e.response.status+')'
                  });
            }
            if (e.request) {
                ReactGA.event({
                    category: 'User',
                    action: 'Recover Password System Error'
                  });
                setSystemError(true);
            }
          });
    };
    
    return (
        <>
            <Helmet>
                <title>{(process.env.REACT_APP_TITLE as string) + ' - Recuperação de acesso'}</title>
                <link rel="canonical" href={(process.env.REACT_APP_BASE_DOMAIN as string) + window.location.pathname + window.location.search} />
            </Helmet>

            <div id="home" className="text-center header-hero bg_cover d-lg-flex align-items-center" style={{ backgroundImage: "url(assets/images/header-hero.jpg)" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <img src="assets/images/logo4.png" style={{width: "250px"}} alt="logo"/>
                            <h4 className="text wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s" style={{paddingLeft: "20px"}}>Informe nos campos abaixo o seu e-mail para o envio ao seu contato de seguran&ccedil;a as instru&ccedil;&otilde;es para recuperar a sua senha.</h4>
                            <div className="header-hero-content">
                                {systemError === true ?                                
                                    <h5 className="alert alert-warning">Ocorreu um comportamento inesperado, por favor, aguarde alguns minutos e tente novamente. Caso a mensagem continue a ser apresentada, fa&ccedil;a contato atrav&eacute;s do e-mail <a href="mailto:suporte@4fuse.com.br?subject=Ocorreu um problema para recuperar a minha senha">suporte@4fuse.com.br</a> </h5>
                                    : ''
                                }
                                {recaptchaVerified === false && tryChallengeRecaptcha === true ?                                
                                    <h5 className="alert alert-danger">Identificamos um poss&iacute;vel acesso de rob&ocirc;, por favor, atualize a p&aacute;gina e tente novamente. Caso a mensagem continue a ser apresentada, fa&ccedil;a contato atrav&eacute;s do e-mail <a href="mailto:suporte@4fuse.com.br?subject=Estou sendo identificado como bot">suporte@4fuse.com.br</a> </h5>
                                    : ''
                                }
                                {emailConfirmation !== '' ?                                
                                    <h5 className="alert alert-success">
                                        <strong>Enviamos um e-mail com as instru&ccedil;&otilde;es para altera&ccedil;&atilde;o de senha ao endere&ccedil;o: <span>{emailConfirmation}</span>.</strong> <br />
                                        <small>Em instantes voc&ecirc; ser&aacute; redirecionado para a p&aacute;gina princial do nosso site para efetuar o seu acesso ou <a href="/">clique aqui</a>.</small>
                                    </h5>
                                    : ''
                                }
                                {emailConfirmation === '' ?
                                <span style={{width: "100%", maxWidth: "100%"}}>
                                    <div className="header-singup wow fadeInUp" style={{marginTop:"10px"}} data-wow-duration="1s" data-wow-delay="0.8s">
                                        <input type="text" placeholder="seuemail@dominio.br" value={email} onChange={(e)=> {setSystemError(false); setEmail(e.target.value);}}/>
                                        <button className="main-btn" onClick={(e)=>recoverPassword()}>Solicitar nova senha</button>
                                    </div>
                                    <p>caso deseje retornar para o site principal <a href="/">clique aqui</a></p>
                                </span>
                                    : ''
                                }
                                <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
                                    <GoogleReCaptcha onVerify={handleVerify} />
                                </GoogleReCaptchaProvider>
                            </div> 
                        </div>
                    </div> 
                </div> 
                <div className="header-hero-image d-flex align-items-center wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="1.1s">
                    <div className="image">
                        <img src="assets/images/header-software-app-10.jpg" style={{height: "400px", marginLeft: "100px"}} alt="Gerencie o seu negócio"/>
                    </div>
                </div> 
            </div>
        </>
        );
};

export default ForgotPassword;