import { CookiesProvider } from 'react-cookie'
import ReactGA from 'react-ga'
import {
  BrowserRouter as Router, Redirect, Route, Switch
} from "react-router-dom"
import {Helmet} from "react-helmet";
import AgreementCookies from './components/AgreementCookies'
import BackToTop from './components/BackToTop'
import Footer from './components/Footer'
import Preload from './components/Preload'
import ChangePassword from './pages/ChangePasswordPage'
import ForgotPassword from './pages/ForgotPasswordPage'
import Home from './pages/HomePage'
import LoginView from './pages/LoginPage'
import SuspendPassword from './pages/SuspendPasswordPage'

ReactGA.initialize(process.env.REACT_APP_ANALYTICS_KEY as string);

const App = () => {

  ReactGA.pageview(window.location.pathname + window.location.search);

  const pathname = window.location.pathname;
  
  let footer = <Footer></Footer>;
  let backToTop = <BackToTop></BackToTop>;
  
  if (pathname === '/login') {
    footer = <></>;  
    backToTop = <></>;  
  }

  return( 
    <>
      <Helmet>
          <title>{process.env.REACT_APP_TITLE as string}</title>
          <link rel="canonical" href={(process.env.REACT_APP_BASE_DOMAIN as string) + window.location.pathname + window.location.search} />
      </Helmet>
      <CookiesProvider>
        <Router>

            <Preload></Preload>

            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/login">
                <LoginView />
              </Route>
              <Route path="/forgot-password">
                <ForgotPassword />
              </Route>
              <Route path="/recovery-password">
                <ChangePassword />
              </Route>
              <Route path="/suspend-password">
                <SuspendPassword />
              </Route>
              <Redirect to="/" />
            </Switch>

            {footer}

            {backToTop}
            
            <AgreementCookies></AgreementCookies>
        </Router>
      </CookiesProvider>
    </>
    );
}

export default App;
